<template>
	<div class="doc-container">
		<div class="doc-header">
			<span class="title">帮助文档｜微信支付配置</span>
		</div>
		<div class="doc-scroll">
			<div class="doc-main">
				<div class="doc-meau">
					<div class="doc-meau-contnet">
						<div class="doc-meau-directory">
							<div class="doc-directory-title">目录</div>
							<div class="doc-directory-container">
								<ul class="doc-directory-list">
									<li :class="['toc-entry', 'heading-1',focusIndex === 0 ? 'is-active': '']">
										<div class="dic-text" style="font-weight: 700;" @click="onScrollToRef('section1')">1、开通提现功能</div>
									</li>
									<li :class="['toc-entry','heading-1',focusIndex === 1 ? 'is-active': '']">
										<div class="dic-text" style="font-weight: 700;" @click="onScrollToRef('section2')">2、开通红包功能</div>
									</li>
									<li :class="['toc-entry', 'heading-1',focusIndex === 2 ? 'is-active': '']">
										<div class="dic-text" style="font-weight: 700;" @click="onScrollToRef('section3')">3、关联微信支付商户号</div>
									</li>
									<li :class="['toc-entry','heading-1',focusIndex === 3 ? 'is-active': '']">
										<div class="dic-text" style="font-weight: 700;" @click="onScrollToRef('section4')">4、商户号信息设置</div>
									</li>
									<li :class="['toc-entry','heading-1',focusIndex === 4 ? 'is-active': '']">
										<div class="dic-text" style="font-weight: 700;" @click="onScrollToRef('section5')">5、微信支付API证书和密钥配置</div>
									</li>
									<li :class="['toc-entry','heading-1',focusIndex === 5 ? 'is-active': '']">
										<div class="dic-text" style="font-weight: 700;" @click="onScrollToRef('section6')">6、账户充值</div>
									</li>
									<li :class="['toc-entry','heading-1',focusIndex === 6 ? 'is-active': '']">
										<div class="dic-text" style="font-weight: 700;" @click="onScrollToRef('section7')">7、提现规则说明</div>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<h3 class="doc-title">帮助文档｜微信支付配置</h3>
				<div class="doc-document">
					<p class="waring-tips">注意：微信支付商户号使用「现金红包」和「提现至零钱」功能，需满足“已入驻90日且截止今日回推30天商户号保持连续不间的交易”，且“保持正常健康交易”。<br>如不满足以上条件，暂时无法使用微信支付能力给员工返佣，以及红包功能发红包给微信用户。</p>
					<h6 ref="section1" class="sub-title">1、开通提现功能</h6>
					<h7 class="small-title">
						<p class="small-title-desc" style="padding-left: 10px;">1-1、如需使用「线索分佣」和「订单分佣」功能，则需开通微信支付商户的「企业付款到零钱」功能；（如不需要，忽略此步骤）</p>
					</h7>
					<h7 class="small-title">
						<p class="small-title-desc" style="padding-left: 10px;">1-2、具体开通步骤如下：（<a href="https://pay.weixin.qq.com/wiki/doc/api/tools/mch_pay.php?chapter=14_2" target="_blank">微信企业付款至零钱接口文档</a>）</p>
					</h7>
					<h7 class="small-title">
						<p class="small-title-desc" style="padding-left: 20px;">1-2-1、登录微信支付后台，进入【产品中心】页，找到【运营工具】分类下的【企业付款到零钱】功能，点击开通；</p>
					</h7>
					<p class="mod-img" style="margin-bottom:20px">
						<img class="help-imgs" alt="" :src="imgs[0]" @click="onprevImg(0)">
					</p>
					<h7 class="small-title">
						<p class="small-title-desc" style="padding-left: 20px;">1-2-2、开通后，回到「企业付款到零钱」产品详情页，点击【产品设置】按钮；</p>
					</h7>
					<p class="mod-img" style="margin-bottom:20px">
						<img class="help-imgs" alt="" :src="imgs[1]" @click="onprevImg(1)">
					</p>
					<h7 class="small-title">
						<p class="small-title-desc" style="padding-left: 20px;">1-2-3、再找到【付款发起方式】项，点击「设置」按钮；</p>
					</h7>
					<p class="mod-img" style="margin-bottom:20px">
						<img class="help-imgs" alt="" :src="imgs[2]" @click="onprevImg(2)">
					</p>
					<h7 class="small-title">
						<p class="small-title-desc" style="padding-left: 20px;">1-2-4、付款方式勾选【在商户平台页面付款】和【使用API接口发起付款】</p>
						<p class="small-title-desc" style="padding-left: 20px;">添加2组IP地址（1个IP地址单独一行）：</p>
						<ul style="margin:0 0 0 10px;">
							<li><span style="color:#1890ff;">106.12.145.118</span></li>
							<li><span style="color:#1890ff;">106.12.145.119</span></li>
						</ul>
					</h7>
					<p class="mod-img" style="margin-bottom:20px">
						<img class="help-imgs" alt="" :src="imgs[3]" @click="onprevImg(3)">
					</p>
					<h7 class="small-title">
						<p class="small-title-desc" style="padding-left: 20px;">1-2-5、如需<span style="color:#e8323c;">限制单次提现金额</span>，可继续在【产品设置】页找到【商户付款额度】项，点击单笔付款区间【调整额度】按钮，可设置最小提现0.3元（如需提现小于0.3元，需联系微信运营申请开通）。</p>
					</h7>
					<p class="mod-img" style="margin-bottom:20px">
						<img class="help-imgs" alt="" :src="imgs[4]" @click="onprevImg(4)">
					</p>
					<h6 ref="section2" class="sub-title">2、开通红包功能</h6>
					<h7 class="small-title">
						<p class="small-title-desc" style="padding-left: 10px;">2-1、如需使用「<span style="font-weight:600;">访问分佣</span>」功能，则需开通微信支付商户的「现金红包」功能；（如不需要，忽略此步骤）</p>
					</h7>
					<h7 class="small-title">
						<p class="small-title-desc" style="padding-left: 10px;">2-2、具体开通步骤如下：（<a href="https://pay.weixin.qq.com/wiki/doc/api/tools/cash_coupon.php?chapter=13_4&index=3" target="_blank">微信红包发放接口文档</a>）</p>
					</h7>
					<h7 class="small-title">
						<p class="small-title-desc" style="padding-left: 20px;">2-2-1、  登录微信支付后台，进入【产品中心】页，找到【运营工具】分类下的【现金红包】功能，点击开通；</p>
					</h7>
					<p class="mod-img" style="margin-bottom:20px">
						<img class="help-imgs" alt="" :src="imgs[5]" @click="onprevImg(5)">
					</p>
					<h6 ref="section3" class="sub-title">3、关联微信支付商户号</h6>
					<h7 class="small-title">
						<p class="small-title-desc" style="padding-left: 10px;">3-1、进入微信公众平台，找到【广告与服务】-【微信支付】模块，打开【商户号管理】栏目，</p>
						<p class="small-title-desc" style="padding-left: 10px;">3-2、先复制「页面上的AppID」号，再点击【关联更多商户号】按钮进行关联；</p>
					</h7>
					<p class="mod-img" style="margin-bottom:20px">
						<img class="help-imgs" alt="" :src="imgs[6]" @click="onprevImg(6)">
					</p>
					<h7 class="small-title">
						<p class="small-title-desc" style="padding-left: 10px;">3-3、页面跳转到【微信支付商户平台】端，微信商户号管理员扫码登录；</p>
						<p class="small-title-desc" style="padding-left: 10px;">3-4、再找到【产品中心】-【AppID账号管理】-【我关联的AppID账号】模块，点击【关联AppID】按钮；</p>
					</h7>
					<p class="mod-img" style="margin-bottom:20px">
						<img class="help-imgs" alt="" :src="imgs[7]" @click="onprevImg(7)">
					</p>
					<h7 class="small-title">
						<p class="small-title-desc" style="padding-left: 10px;">3-5、将【公众号AppID】粘贴到指定位置，勾选同意按钮后，出现【AppID认证主体】项，填入相应信息；（可查看微信官方指引教程找到需填信息的位置）；</p>
					</h7>
					<p class="mod-img" style="margin-bottom:20px">
						<img class="help-imgs" alt="" :src="imgs[8]" @click="onprevImg(8)">
					</p>
					<h7 class="small-title">
						<p class="small-title-desc" style="padding-left: 10px;">3-6、提交完成后，再回到公众号平台【微信支付】-【商户号管理】-【待关联商户号】模块，此时出现刚授权关联的商户号信息，点击【通过】按钮即完成关联操作。</p>
					</h7>
					<p class="mod-img" style="margin-bottom:20px">
						<img class="help-imgs" alt="" :src="imgs[9]" @click="onprevImg(9)">
					</p>
					<h7 class="small-title">
						<p class="small-title-desc" style="padding-left: 10px;">（注意：商户号与微信公众号建立关联关系后，暂不支持解绑）</p>
					</h7>
					<h6 ref="section4" class="sub-title">4、商户号信息设置</h6>
					<h7 class="small-title">
						<p class="small-title-desc" style="padding-left: 10px;">4-1、前往微信支付商户平台端，在【账户中心】-【商户信息】模块，复制「商户号」，粘贴到企业号系统中的「商户号(MCHID)」输入框内；</p>
					</h7>
					<p class="mod-img" style="margin-bottom:20px">
						<img class="help-imgs" alt="" :src="imgs[10]" @click="onprevImg(10)">
					</p>
					<h7 class="small-title">
						<p class="small-title-desc" style="padding-left: 10px;">4-2、再回到【产品中心】-【开发配置】模块，找到【Native支付回调】项，复制企业号中的「回调地址」粘贴到输入框内，并保存；</p>
					</h7>
					<h6 ref="section5" class="sub-title">5、微信支付API证书和密钥配置</h6>
					<h7 class="small-title">
						<p class="small-title-desc" style="padding-left: 10px;">5-1、找到【账户中心】-【API安全】模块，找到【API证书】项，下载证书；（如果未开通，则按照<a href="https://pay.weixin.qq.com/wiki/doc/apiv3/open/pay/chapter5_7_2.shtml#part-5" target="_blank">微信支付平台指引文档</a>进行安装）</p>
					</h7>
					<h7 class="small-title">
						<p class="small-title-desc" style="padding-left: 10px;">5-2、将下载好的证书，解压到电脑桌面，打开解压文件找到文件名<span style="font-weight:600;">apiclient_cert.p12</span>，上传到【企业号】-【微信授权】的「商户支付API证书」位置；</p>
					</h7>
					<p class="mod-img" style="margin-bottom:20px">
						<img class="help-imgs" alt="" :src="imgs[11]" @click="onprevImg(11)">
					</p>
					<p class="mod-img" style="margin-bottom:20px">
						<img class="help-imgs" alt="" :src="imgs[12]" @click="onprevImg(12)">
					</p>
					<h7 class="small-title">
						<p class="small-title-desc" style="padding-left: 10px;">5-3、再回到微信商户平台，找到【账户中心】-【API安全】模块，找到【API密钥】项，<span style="color:#e8323c;">进行密钥设置</span>；再将设置好的密钥粘贴到【企业号】-【微信授权】的「商户支付密钥」位置；</p>
					</h7>
					<p class="mod-img" style="margin-bottom:20px">
						<img class="help-imgs" alt="" :src="imgs[13]" @click="onprevImg(13)">
					</p>
					<p class="mod-img" style="margin-bottom:20px">
						<img class="help-imgs" alt="" :src="imgs[14]" @click="onprevImg(14)">
					</p>
					<h7 class="small-title">
						<p class="small-title-desc" style="padding-left: 10px;">5-4、设置密匙时一定要按照下图箭头所示的规定设置。32个字符，包含英文和数字，英文要同时有大写和小写。如：bianchengxiaoshitou2501902696AAA；（<span style="color:#e8323c;">设置的32位的密匙，需要我们自己牢牢记住的。支付后台不会为我们保存的</span>）</p>
					</h7>
					<p class="mod-img" style="margin-bottom:20px">
						<img class="help-imgs" alt="" :src="imgs[15]" @click="onprevImg(15)">
					</p>
					<h7 class="small-title">
						<p class="small-title-desc" style="padding-left: 10px;">5-5、回到企业号系统中，点击「保存」按钮，即完成了配置；</p>
					</h7>
					<h6 ref="section6" class="sub-title">6、账户充值</h6>
					<h7 class="small-title">
						<p class="small-title-desc" style="padding-left: 10px;">6-1、注意：微信商户的账户分为3类：基本账户（默认账户）、手续费账户（需开通）、运营账户（需开通）； 账户支出分为2种情况，如下：</p>
					</h7>
					<h7 class="small-title">
						<p class="small-title-desc" style="padding-left: 20px;">6-1-1、<span style="font-weight:600;">情况一</span>：如只开通【<span style="color:#e8323c;">基本账户</span>】，则保证账户内金额充足，提现和发红包都从该账户内支出；</p>
					</h7>
					<h7 class="small-title">
						<p class="small-title-desc" style="padding-left: 30px;">■ <span style="color:#f5222d;">充值方法</span>：找到【交易中心】-【资金管理】-【充值/转入】模块，将「入款账户」项选择【基本账户】，并存入金额。</p>
					</h7>
					<h7 class="small-title">
						<p class="small-title-desc" style="padding-left: 20px;">6-1-2、<span style="font-weight:600;">情况二</span>：如开通了【<span style="color:#e8323c;">运营账户</span>】，则根据微信规则所有运营支出都从由账户管理，因此保证运营账户金额充足，提现和发红包都从该账户内支出；</p>
					</h7>
					<h7 class="small-title">
						<p class="small-title-desc" style="padding-left: 30px;">■ <span style="color:#f5222d;">充值方法</span>：找到【交易中心】-【资金管理】-【充值/转入】模块，将「入款账户」项选择【运营账户】，并存入金额。</p>
					</h7>
					<p class="mod-img" style="margin-bottom:20px">
						<img class="help-imgs" alt="" :src="imgs[16]" @click="onprevImg(16)">
					</p>
					<h7 class="small-title">
						<p class="small-title-desc" style="padding-left: 20px;">以上步骤都完成，并充值完成后，企业员工即可完成提现至个人微信零钱（实现佣金提现），以及公众号发红包给微信用户（实现访问分佣）。</p>
					</h7>
					<h6 ref="section7" class="sub-title">7、提现规则说明</h6>
					<h7 class="small-title">
						<p class="small-title-desc" style="padding-left: 10px;">7-1、<span style="font-weight:600;">【发放红包】</span>：单次红包金额在系统中开启「访问分佣模式」时设置（设置区间为<span style="color:#e8323c;">1～200</span>元），有系统随机计算给出红包金额，再通过微信公众号发放；单个微信用户单日最多可领取<span style="color:#e8323c;">10</span>个红包；</p>
					</h7>
					<h7 class="small-title">
						<p class="small-title-desc" style="padding-left: 10px;">7-2、<span style="font-weight:600;">【账户提现】</span>：每日只允许提现<span style="color:#e8323c;">1</span>次（按提现成功计算），单次提现金额必须大于<span style="color:#e8323c;">1</span>元，且最高金额不超过<span style="color:#e8323c;">5000</span>元；</p>
					</h7>
				</div>
			</div>
		</div>
		<jw-img-view :imgs="imgs" :visible.sync="visible" :select.sync="select" />
	</div>
</template>

<script>
var throttle = function (func, delay) {
  var timer = null
  return function () {
    var context = this
    var args = arguments
    if (!timer) {
      timer = setTimeout(function () {
        func.apply(context, args)
        timer = null
      }, delay)
    }
  }
}

export default {
  data () {
    return {
      visible: false,
      select: 0,
      focusIndex: null,
      imgs: [
        'https://xmf-bos.jwyun.net/hnjing-xmf/6db85fc24a7d375d8e0ae45c8624aa7d.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100',
        'https://xmf-bos.jwyun.net/hnjing-xmf/4a840263acacf18a78c0e579886fe9f3.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100',
        'https://xmf-bos.jwyun.net/hnjing-xmf/cfb9fc6f86a64584aad73f7805177412.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100',
        'https://xmf-bos.jwyun.net/hnjing-xmf/e29e951e3489480b38861e9344c6b8e2.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100',
        'https://xmf-bos.jwyun.net/hnjing-xmf/f65296a4d09d81f5fee0a4b5ec02259f.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100',
        'https://xmf-bos.jwyun.net/hnjing-xmf/1b74953c388db6c18ca944b8dd128cc7.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100',
        'https://xmf-bos.jwyun.net/hnjing-xmf/99e18a379760f876ab798e0fc79e28ba.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100',
        'https://xmf-bos.jwyun.net/hnjing-xmf/4ddb4ebe696e419212f541fed0c7ebd0.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100',
        'https://xmf-bos.jwyun.net/hnjing-xmf/66ce6d144728a2c80a76173a048009f4.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100',
        'https://xmf-bos.jwyun.net/hnjing-xmf/8c42e9a951465034270c4c751d75cac6.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100',
        'https://xmf-bos.jwyun.net/hnjing-xmf/5e91f414240655ecec27024d070377c2.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100',
        'https://xmf-bos.jwyun.net/hnjing-xmf/98496ff1d196a1b1e0771fa1528ad0af.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100',
        'https://xmf-bos.jwyun.net/hnjing-xmf/5dd96225aa3034f0bf62f231636f8c1b.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100',
        'https://xmf-bos.jwyun.net/hnjing-xmf/ca284abbffc40073478c79459ae815d7.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100',
        'https://xmf-bos.jwyun.net/hnjing-xmf/ce9af3b100c6a6f6787446620418ff1d.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100',
        'https://xmf-bos.jwyun.net/hnjing-xmf/743e8d07f6e022d2fee3cd688be442dd.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100',
        'https://xmf-bos.jwyun.net/hnjing-xmf/415dd485f79970be31e4788f9d1743ed.png?x-bce-process=image/format,f_auto/interlace,i_progressive/quality,q_100'
      ]
    }
  },
  mounted () {
    this.$el.querySelector('.doc-scroll').addEventListener('scroll', this.onscrollSection)
  },
  beforeDestroy () {
    this.$el.querySelector('.doc-scroll').removeEventListener('scroll', this.onscrollSection)
  },
  methods: {
    onscrollSection: throttle(function (e) {
      const indexs = [90, 2100, 2500, 4100, 4600, 6700, 7500]
      const height = e.target.scrollTop
      for (let i = 0; i < indexs.length; i++) {
        if ((i === (indexs.length - 1) && height > indexs[indexs.length - 1]) || (height > indexs[i] && height < indexs[i + 1])) {
          this.focusIndex = i
          break
        }
      }
    }, 200),
    onprevImg (index) {
      this.select = index
      this.visible = true
    },
    onSwtichOneTable () {
      this.levelOneIsCollapsed = !this.levelOneIsCollapsed
    },
    onScrollToRef (ref) {
      console.log(this.$refs[ref].offsetTop)
      this.$el.querySelector('.doc-scroll').scrollTo(0, this.$refs[ref].offsetTop)
      setTimeout(() => {
        this.$refs[ref].className = [this.$refs[ref].className, 'is-focus'].join(' ')
        setTimeout(() => {
          this.$refs[ref].className = this.$refs[ref].className.split(' ').filter(item => item !== 'is-focus').join(' ')
        }, 600)
      }, 700)
    }
  }
}
</script>

<style lang="less">
.doc-container{
  width: 100%;
  height: 100%;
  background: #f7f7f7;
  overflow: hidden;
  min-width: 1280px;
  .doc-header{
    white-space: nowrap;
    width: 100%;
    position: relative;
    background: linear-gradient(180deg,#fdfdfd,#fff);
    z-index: 110;
    height: 50px;
    box-shadow: 0 2px 3px 0 rgba(100,100,100,0.15);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    overflow: hidden;
    border-bottom: 1px solid #fff;
    .title{
      display: inline-block;
      vertical-align: middle;
      pointer-events: none;
      height: 50px;
      line-height: 24px;
      margin-left: 240px;
      box-shadow: none;
      background: inherit;
      max-width: 656px;
      padding-bottom: 4px;
      font-size: 14px;
      font-weight: 700;
      white-space: nowrap;
      text-overflow: ellipsis;
      line-height: 50px;
    }
  }
  .doc-scroll{
    height: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
    padding-top: 15px;
    scroll-behavior: smooth;
    .doc-main{
      margin: 0 auto;
      width: 816px;
      min-height: 800px;
      border: none;
      font-family: -apple-system,BlinkMacSystemFont,PingFang SC,Apple Color Emoji,Helvetica,Tahoma,Arial,Hiragino Sans GB,Microsoft YaHei,sans-serif;
      -webkit-font-smoothing: antialiased;
      height: auto!important;
      background: #fff;
      box-shadow: 0 1px 5px #ddd;
      .doc-title{
        box-sizing: content-box;
        margin: 0 100px;
        width: auto;
        font-size: 30px;
        height: 60px;
        line-height: 40px;
        padding-top: 90px;
        border-bottom: 1px solid #e8ecf1;
        font-family: -apple-system,BlinkMacSystemFont,PingFang SC,Apple Color Emoji,Helvetica,Tahoma,Arial,Hiragino Sans GB,Microsoft YaHei,sans-serif;
      }
      .doc-document{
        position: relative;
        padding: 20px 90px 100px;
        font-size: 11pt;
        line-height: 1.7;
        color: #494949;
        outline: none;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        min-height: 1172px;
        box-sizing: border-box;
        text-align: left;
        white-space: pre-wrap;
        word-wrap: break-word;
        counter-reset: chapter section subsec;
        .waring-tips{
          color: #ff0000;
          font-size: 15px;
          font-weight: 700;
        }
        .main-title{
          font-size: 16pt;
          line-height: 36px;
          color: #333;
          margin:0;
          padding-bottom: 8px;
          &.is-focus{
            background-color: rgba(245,212,122,.7);
          }
        }
        .sub-title {
          line-height: 28px;
          font-size: 18px;
          font-weight: bold;
          color: #333;
          // text-indent: 10px;
          margin: 0;
          &.is-focus{
            background-color: rgba(245,212,122,.7);
          }
        }
        .small-title{
          position: relative;
          line-height: 26px;
          &.is-focus{
            background-color: rgba(245,212,122,.7);
          }
          &.blod{
            font-weight: bold;
            .small-title-word{
              font-weight: bold;
            }
          }
          .small-title-word{
            font-style: normal;
            font-weight: normal;
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 60px;
            text-indent: 30px;
            z-index: 1;
            &.word-mark::after{
              // content: "\F104";
              content: '';
              background: #fff;
              display: inline-block;
              width: 4px;
              height: 4px;
              border: 1px solid #494949;;
            }
          }
          .small-title-desc{
            box-sizing: border-box;
            padding-left: 60px;
            &.is-focus{
              background-color: rgba(245,212,122,.7);
            }
            a{
              color: #70b1e7!important;
              cursor: pointer!important;
              text-decoration: none;
              word-break: break-all;
              a:hover{
                color: #1787cf!important;
              }
            }
          }
        }
        .mod-img {
          margin: 0;
          padding: 0;
          .help-imgs{
            max-width: 100%;
            display: block;
            opacity: 1;
            transition: opacity .5s ease-out;
            transform-origin: left top;
            max-width: 100%;
            outline: none;
            outline-offset: inherit;
            border: none;
            box-shadow: none;
            cursor: zoom-in;
          }
        }
        .mod-tips {
          margin:0;
          padding:0;
          a{
            color: #70b1e7!important;
            cursor: pointer!important;
            text-decoration: none;
            word-break: break-all;
            padding: 0 2px;
            a:hover{
              color: #1787cf!important;
            }
          }
          img{
            display: inline-block;
            vertical-align: text-bottom;
            margin-left: 14px;
            margin-top: 10px;
          }
        }
      }
      .doc-meau{
        position: fixed;
        box-sizing: border-box;
        top: 65px;
        height: 480px;
        width: 315px;
        margin-left: -315px;
        .doc-meau-contnet{
          position: absolute;
          width: 100%;
          min-width: 171px;
          font-size: 12px;
          box-sizing: border-box;
          padding: 0 20px 0 0;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          .doc-meau-directory{
            position: relative;
            visibility: visible;
            opacity: 1;
            width: 100%;
            height: 100%;
            text-align: left;
            color: #ccc;
            margin: 20px 0;
            padding: 0;
            overflow: hidden;
            transition: visibility .3s,opacity .3s ease-in-out;
            .doc-directory-title{
              position: relative;
              z-index: 2;
              background: #f7f7f7;
              color: #a5a5a5;
              border-bottom: 1px solid #ddd;
              padding-bottom: 10px;
              margin-left: 15px;
            }
            .doc-directory-container{
              max-height: 100%;
              box-sizing: border-box;
              overflow: hidden;
              padding-right: 7px;
              transition: transform .25s ease-in-out;
              transform: translateY(0);
              -ms-touch-action: auto;
              touch-action: auto;
              .doc-directory-list{
                height: auto;
                padding: 10px 0;
                list-style: none;
                margin: 0;
                li.toc-entry{
                  line-height: 18px;
                  border: none;
                  cursor: pointer;
                  padding: 6px 0 6px 15px;
                  color: #666;
                  &::before{
                    content: '';
                  }
                  &:hover{
                    color: #5e97d4;
                    .icon-collapsed{
                      display: block;
                    }
                  }
                  &.is-active{
                    color: #5e97d4;
                  }
                  &.heading-2{
                    margin-left: 15px;
                    padding-left: 10pt;
                  }
                  &.heading-3{
                    margin-left: 15px;
                    padding-left: 20pt;
                  }
                  &.is-collapsed .icon-collapsed {
                    display: block;
                    svg{
                      transform: rotate(0deg);
                    }
                  }
                  .icon-collapsed{
                    width: 15px;
                    height: 18px;
                    position: absolute;
                    padding-right: 2px;
                    padding-left: 3px;
                    transform: translateX(-100%);
                    display: none;
                    svg{
                      width: 10px;
                      height: 10px;
                      transform: rotate(90deg);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
